const titles = {
    patientProfileList: "Patient Profile List",
    hello: "Hello",
    patientName: "Patient Name",
    patientId: "Patient Id",
    creationDate: "Creation Date",
    lastUpdate: "Last Update",
    status: "Status",
    action: "Action",
    profileId: "Profile Id",
    sizeOfProfilePopulation: "Size of Profile Population",
    numberOfInclusionRules: "Number Of Inclusion Rules",
    numberOfRiskGroups: "Number Of Risk Groups",
    modelProfileList: "Model Profile List",
    view: "View",
    export: "Export",
    import: "Import",
    completed: "Completed",
    delete: "Delete",
    generatePrediction: "Generate Prediction",
    draft: "Draft",
    addNewPatient: "Add New Patient",
    patientProfile: "Patient Profile",
    predictionGenerators: "Prediction Generators",
    readMission: "Readmission",
    basedOnModels: "Based On Models",
    frailtyPredictions: "Frailty Predictions",
    predictedIndividualTreatmentEffect: "Predicted Individual Treatment Effect",
    deleteProfile: "Delete Profile",
    editProfile: "Edit Profile",
    dashboard: "Dashboard",
    predictionModel: "WP4 Readmission model",
    update: "Update",
    editPatientProfile: "Edit Patient Profile",
    generalInformation: "General Information",
    fullName: "Full Name",
    countryOfOrigin: "Country of Origin",
    chf: "CHF",
    copd: "COPD",
    uti: "UTI",
    emailAddress: "Email Address",
    password: "Password",
    forgotPassword: "Forgot Password",
    login: "Login",
    signInToYourAccount: "Sign in to your account",
    welcomeBack: "Welcome Back",
    hospitalization_history: "HOSPITALIZATION HISTORY",
    usti: "UTI",
    deteriorated_more_services: "DETERIORATED MORE SERVICES",
    stasis_ulcer: "STASIS ULCER",
    skin_tears_or_cut: "SKIN TEARS OR CUT",
    lessons: "LESSONS",
    fallany: "FALLANY",
    other_fracture: "OTHER FRACTURE",
    cancer: "CANCER",
    infuse: "INFUSE",
    dialysis: "DIALYSIS",
    er_visits_in_the_last_90_days_or_since_last_assessment: "ER VISITS IN THE LAST 90 DAYS OR SINCE LAST ASSESSMENT",
    renal_failure: " RENAL FAILURE",
    gender: "GENDER",
    irregular_pulse: "IRREGULAR PULSE",
    unintended_weight_loss_of_5_percent_or_more_in_last_30_days: "UNINTENDED WEIGHT LOSS OF 5(%) OR MORE IN LAST 30 DAYS",
    ulcer_wound_care_surgical_wound_care: "ULCER WOUND CARE SURGICAL WOUND CARE",
    pressure_ulce: "PRESSURE ULCE",
    hip_fracture: "HIP FRACTURE",
    client_feels_having_a_poor_health: "CLIENT FEELS HAVING A POOR HEALTH",
    experiencing_flare_up_of_recurrent_condition_or_problem: "EXPERIENCING FLARE UP OF RECURRENT CONDITION OR PROBLEM",
    adl_decline: "ADL DECLINE",
    baseline_frailty_score: "BASELINE FRAILTY SCORE",
    baseline_frailty_subset1_cognition_collapsed: " BASELINE FRAILTY SUBSET1 COGNITION COLLAPSED",
    baseline_frailty_subset1_clinical_collapsed: "BASELINE FRAILTY SUBSET1 CLINICAL COLLAPSED",
    short_term_memory: "SHORT TERM MEMORY",
    worsening_of_decision_making: "WORSENING OF DECISION MAKING",
    alzheimers: "ALZHEIMERS",
    dementia_other_than_heart_disease: "DEMENTIA OTHER THAN HEART DISEASE",
    parkinsonism: "PARKINSONISM",
    eating: "EATING",
    tranferring_oneself_from_surfaces: "TRANFERRING ONESELF FROM SURFACES",
    bladder_devices_indwelling_urinary_catheter: "BLADDER DEVICES INDWELLING URINARY CATHETER",
    pneumonia: "PNEUMONIA",
    procedural_memory: "PROCEDURAL MEMORY",
    fever: "FEVER",
    diagnosis: "Diagnosis",
    age: "Age",
    unplanned_hospital_or_er_visit: "UNPLANNED HOSPITAL OR ER VISIT",
    number_of_comorbidities: "NUMBER OF COMORBIDITIES",
    unsteady_gait: "UNSTEADY GAIT",
    diuretics: "DIURETICS",
    pain_scale: "PAIN SCALE",
    client_feels_he_she_has_poor_health: "CLIENT FEELS HE SHE HAS POOR HEALTH",
    chess: "CHESS",
    adl_status_has_become_worse: "ADL STATUS HAS BECOME WORSE",
    security_service: "SECURITY SERVICE",
    visiting_nurses: "VISITING NURSES",
    client_went_out_of_the_house_1_day_a_week_or_no_days: "Client went out of the house (1 day a week or no days)",
    impaired_vision: "IMPAIRED VISION",
    caregivers_belief_client_capable: "CAREGIVERS BELIEF CLIENT CAPABLE",
    drs: "DRS",
    psychotropic_medication: "PSYCHOTROPIC MEDICATION",
    bmi: "BMI",
    adl_long: "ADL LONG",
    cancel: "Cancel",
    saveAndClose: "Save and Close",
    enterToSearch: "Enter to Search",
    viewModelList: "View Model List",
    chancesHospitalizationInNext12Months: "Chances hospitalization in next 12 months",
    inclusionRules: "Inclusion Rules",
    variableIndex: "Variable Index",
    variableName: "variable Name",
    variableValue: "Variable Value",
    valueSymetricOddScore: "Value (Symetric) Odd Score",
    oddSymetricScoreSet: "Odd Symetric Score Set",
    riskGroups: "Risk Groups",
    threshold: "Threshold",
    dependent_value: "Dependent Value",
    performance: "Performance",
    sample_size: "Sample Size",
    previous_hospitalization_events_in_last_year: "PREVIOUS HOSPITALIZATION EVENTS IN LAST YEAR",
    welcome: "Welcome!",
    back: "Back",
    frailtyPrediction: "Frailty Prediction",
    viewModelProfile: "View Model Profile",
    users: "Users",
    usersList: "Users List",
    addNewUser: "Add New User",
    name: "Name",
    email: "Email",
    isVerified: "Is Verified",
    yes: "Yes",
    no: "No",
    edit: "Edit",
    resendActivationLink: "Resend Activation Link",
    add: "Add",
    user: "User",
    passwordConfirmation: "Password Confirmation",
    newPatientProfile: "New Patient Profile",
    userForm: "User Form",
    modelProfile: "Model Profile",
    newModelProfile: "New Model Profile",
    locomodation_in_home: "Locomodation In Home",
    meal_preparation: "Meal preparation",
    stairs: "Stairs",
    short_term_memory_ok: "Short Term Memory",
    alzheimers_disease: "Alzheimers disease",
    dementia_other_than_alzheimers_disease: "Dementia other than alzheimers disease",
    paraplegia: "Paraplegia",
    mental_health_delusions: "Mental Health Delusions",
    mental_health_hallucinations: "Mental Health hallucinations",
    bowel_continence: "Bowel Continence",
    meal: "Meal",
    housework: "Housework",
    shopping: "Shopping",
    iadl_alzheimers: "Iadl alzheimers",
    other_dementia: "Other dementia",
    bath: "Bath",
    difficulty_meal_preparation: "Difficulty meal preparation",
    revised_iadl_hierarchy: "revised iadl hierarchy",
    short_memory_ok: "Short memory ok",
    iadl_decline: "Iadl decline",
    iadlModel: "WP4 IADL model",
    iadlProfileList: "Iadl Profile List",
    iadlProfile: "Iadl profile",
    viewIadlModelList: "View Iadl Model List",
    viewIadlModel: "View Iadl Model",
    individualTreatment: "Individual treatment",
    seeAll: "See All",
    iadlDecline: "HC: IADL Decline",
    hospitalization: "HC: Hospitalization",
    qualityOfLife: "NH: Quality of life",
    frailty: "NH: Frailty",
    viewQualityOfLifeList: "View Quality Of Life List",
    viewFrailtyList: "View Frailty List",
    viewFrailty: "View Frailty",
    hospitalizationTooltip: "The individual treatment effect (ITE) which is an absolute measure of risk indicating the change (from baseline) in the probability of probability of hospitalization (next 12 months) for that specific individual due to treatment",
    adlTooltip: "The change (from baseline) in the predicted outcome value for that specific individual due to treatment. For example, an ITE of +1.2 score is the change of the predicted score due to ITE (on a scale from 0 to 6) for that specific individual. This number can be expressed in percentage that is the change in CPS score due to ITE is 20% increase (1.2/6*100) for that specific individual",
    cpsTooltip: "The change (from baseline) in the predicted outcome value for that specific individual due to treatment. For example, an ITE of +1.2 score is the change of the predicted score due to ITE (on a scale from 0 to 6) for that specific individual. This number can be expressed in percentage that is the change in CPS score due to ITE is 20% increase (1.2/6*100) for that specific individual ",
    chessTooltip: "The change (from baseline) in the predicted outcome value for that specific individual due to treatment. For example, an ITE of +1.2 score is the change of the predicted score due to ITE (on a scale from 0 to 6) for that specific individual. This number can be expressed in percentage that is the change in CPS score due to ITE is 20% increase (1.2/6*100) for that specific individual",
    fallsTooltip: "The individual treatment effect (ITE) which is an absolute measure of risk indicating the change (from baseline) in the probability of probability of a fall (next 3-6 months) for that specific individual due to treatment ",
    role: "Role",
    isLogin: "Is Login?",
    prePilotDashboard: "Dashboard",
    isVisitFormLink: "Is Visit Form Link?",
    qualityOfLifeN: "Quality of Life",
    frailtyN: "Frailty",
    category: "Category",
    type: "Type",
    baseOnModel: "Base On Model",
    item: "Item",
    addSuccessfully: "Added Successfully",
    updateSuccessfully: "Updated Successfully",
    errorMessage: "Ops, something went wrong, try again",
    questionSubmittedSuccess: "Question Submitted Successfully",
    deletedSuccess: "deleted successfully",
    resetPasswordSuccessMsg: "Reset password link has been sent to your email successfully.",
    successLogin: "You have been successfully logged in",
    otpSuccessMsg: "OTP send to your mail successfully",
    alreadyRegistered: "Already Registered!",
    otpVerifiedMsg: "OTP Verified Successfully !",
    otpSentSuccessMsg: "OTP Sent Successfully !",
    registerSuccessMsg: "Register Successfully !",
    tokenInvalidMsg: "Token invalid or expired, try again",
    passwordResetSuccessMsg: "Password Reset Successfully !",
    yourRequestSubmitSuccessMsg: "Your request submitted successfully",
    patientImportSuccessMsg: "Patient Import Successfully",
    deletedSuccessMsg: "Deleted successfully",
    accountAlreadyExistsMsg: "Your account is  Successfully register with us, please login now !",
    fileUploadSuccessMsg: "File Upload Successfully",
    fileDeleteMsg: "File Delete Successfully",
    click: "Click",
    here: "Here",
    postQuestionnaireSentence: "on this link and fill the form for further process of",
    postQuestionnaire: "post-questionnaire",
    hospitalizationTooltip2: "Probability of hospitalization to a medical facility in the next 12 months.",
    adlTooltip2: "Probability of having significant worsening in IADL in the next 12 months.",
    patient: 'Patient',
    predictionTopic: 'Predictions in 12 months',
    riskOfDeclineOfEvent: "Risk of decline / of event",
    whatIfYouDecideTo: "What if you decide to:",
    stopAntipsychotics: "Stop Antipsychotics",
    startAnticholinergics: "Start Anticholinergics",
    startPhysiotherapy: "Start Physiotherapy",
    predictedChange: "Predicted change",
    adl: "ADL",
    iadl: "IADL",
    cps: "CPS",
    falls: "FALLS",
    configuration: "Configuration",
    code: "Code",
    label: "Label",
    index: "Index",
    wp4: "WP4",
    configurations: "Configurations",
    configurationWp4Form: "Configuration Wp4 Form",
    configurationWp4SectionForm: "Configurations Wp4 Section Form",
    wp4Section: "Wp4 Section",
    title: "Title",
    section: "Section",
    isEmailVerified: "Is Email verified?",
    areYourSureWantToLogout: "Are your sure want to Logout?",
    postQuestion: "for decision questionnaire.",
    toProvideFeedback: "to provide feedback.",
    logout: "Logout",
    clone: "Clone",
    cloneSuccessMsg: "Clone patient data successfully",
    codeSystem: "Code System",
    importExternalPatient: "Import External Patient",
    addPatient: "Add Patient",
    patientList: "Patient List",
    patientForm: "Patient Form",
    save: "Save",
    link1: "Link1",
    link2: "Link2",
    patientFeedbackLink: "Patient Feedback Link",
    forDecisionQuestionnaire: "for Decision questionnaire",
    link: "Link",
    showRaiData: "Show RAI Data",
    close: "Close",
    startPhysiotherapyContent: "timeframe: up to 6 months; Definition of PT : at least 4 sessions per week (for ADLLF) or 5 days (for CPS), each session lasting minimum 15 minutes",
    startPhysiotherapyContent1: "The individual treatment effect (ITE) is the difference between the predicted effect under treatment and the predicted effect if the patient does not receive treatment. A predicted effect lower than 0  indicates less decline, no decline, or greater improvement with therapy compared to no therapy. A predicted effect higher than 0  indicates a greater decline with therapy compared to no therapy",
    startPhysiotherapyContent2: "The individual treatment effect (ITE) is the difference between the predicted effect under treatment and the predicted effect if the patient does not receive treatment. A predicted effect higher than 0 indicates less decline, no decline, or greater improvement with therapy compared to no therapy. A predicted effect lower than 0 indicates a greater decline with therapy compared to no therapy.",
    startPhysiotherapyContentAdl: "The individual treatment effect (ITE) is the difference between the predicted effect under treatment and the predicted effect if the patient does not receive treatment. A predicted effect lower than 0 indicates less decline, no decline, or greater improvement with therapy compared to no therapy. A predicted effect higher than 0 indicates a greater decline with therapy compared to no therapy; Time period: up to 6 months; Outcome: Activities of Daily Living Long Form, from 0 to 28 points",
    stopAntipsychoticsContent1: "The individual treatment effect (ITE) is the difference between the predicted effect after discontinuing antipsychotic medication and the predicted effect of continuing the medication. A predicted effect of less than 0 indicates less decline, no decline, or greater improvement of CHESS with antipsychotic medication discontinuation compared with continued medication. A predicted effect greater than 0 indicates greater decline of CHESS when discontinuing antipsychotic medication compared with continuing medication. Timeframe: up to 12 months",
    stopAntipsychoticsContentAdl: "The individual treatment effect (ITE) is the difference between the predicted effect after discontinuing antipsychotic medication and the predicted effect of continuing the medication. A predicted effect of less than 0 indicates less decline, no decline, or greater improvement of ADL with antipsychotic medication discontinuation compared with continued medication. A predicted effect greater than 0 indicates greater decline of ADL when discontinuing antipsychotic medication compared with continuing medication. Timeframe: up to 12 months",
    stopAntipsychoticsContentCps: "The individual treatment effect (ITE) is the difference between the predicted effect after discontinuing antipsychotic medication and the predicted effect of continuing the medication. A predicted effect of less than 0 indicates less decline, no decline, or greater improvement of CPS with antipsychotic medication discontinuation compared with continued medication. A predicted effect greater than 0 indicates greater decline of CPS when discontinuing antipsychotic medication compared with continuing medication. Timeframe: up to 12 months",
    stopAntipsychoticsContentChess: "The individual treatment effect (ITE) is the difference between the predicted effect after discontinuing antipsychotic medication and the predicted effect of continuing the medication. A predicted effect of less than 0 indicates less decline, no decline, or greater improvement of CHESS with antipsychotic medication discontinuation compared with continued medication. A predicted effect greater than 0 indicates greater decline of CHESS when discontinuing antipsychotic medication compared with continuing medication. Timeframe: up to 12 months",
    stopAntipsychoticsContentFalls: "The individual treatment effect (ITE) is the difference between the predicted effect after starting of anticholinergic medication and the predicted effect if the patient does not receive medication. A predicted effect lower than 0 indicates lower probability of falls after starting of medication compared to no medication. A predicted effect higher than 0 indicates higher probability of falls with medication compared to no medication. timeframe: a lookback period of 90 days after 12 months",
    stopAntipsychoticsContentHospitalization: "The individual treatment effect (ITE) is the difference between the predicted effect after discontinuing antipsychotic medication and the predicted effect of continuing the medication. A predicted effect of less than 0 indicates lower probability of hospitalization with antipsychotic medication discontinuation compared with continued medication. A predicted effect greater than 0 indicates higher probablity of hospitalization when discontinuing antipsychotic medication compared with continuing medication. Timeframe: up to 12 months",
    startAnticholinergicsAdl: "The individual treatment effect (ITE) is the difference between the predicted effect after starting of anticholinergic medication and the predicted effect if the patient does not receive medication. A predicted effect lower than 0 indicates less decline, no decline, or greater improvement of ADL after starting of medication compared to no medication. A predicted effect higher than 0 indicates a greater decline of ADL with medication compared to no medication. timeframe: up to 12 months",
    startAnticholinergicsCps: "The individual treatment effect (ITE) is the difference between the predicted effect after starting of anticholinergic medication and the predicted effect if the patient does not receive medication. A predicted effect lower than 0 indicates less decline, no decline, or greater improvement of CPS after starting of medication compared to no medication. A predicted effect higher than 0 indicates a greater decline of CPS with medication compared to no medication. timeframe: up to 12 months",
    startAnticholinergicsChess: "The individual treatment effect (ITE) is the difference between the predicted effect after starting of anticholinergic medication and the predicted effect if the patient does not receive medication. A predicted effect lower than 0 indicates less decline, no decline, or greater improvement of CHESS after starting of medication  compared to no medication. A predicted effect greater than 0 indicates greater decline of CHESS with medication compared to no medication. Timeframe: up to 12 months",
    startAnticholinergicsHospitalization: "The individual treatment effect (ITE) is the difference between the predicted effect after starting of anticholinergic medication and the predicted effect if the patient does not receive medication. A predicted effect of less than 0 indicates lower probability of hospitalization after starting of medication compared to no medication. A predicted effect higher than 0 indicates a higher probability of hospitalization with medication compared to no medication. timeframe: up to 12 months",
    startAnticholinergicsFalls: "The individual treatment effect (ITE) is the difference between the predicted effect after starting of anticholinergic medication and the predicted effect if the patient does not receive medication. A predicted effect lower than 0 indicates lower probability of falls after starting of medication compared to no medication. A predicted effect higher than 0 indicates higher probability of falls with medication compared to no medication. timeframe: a lookback period of 90 days after 12 months",
    questionnairePopupHeading: "General disclaimer for all predictions",
    questionnairePopupHeadingList1: "Please use caution in your decision and weigh your clinical expertise in your final decision, as the accuracy of models varies across care recipients.",
    questionnairePopupHeadingList2: "These predictions were developed and validated by the I-CARE4OLD consortium",
    questionnairePopupHeadingList3: "Predictions were informed by advanced analyses including machine learning. We applied best science on high quality routine care data from multiple countries.",
    questionnairePopupHeadingList4: "Validations were done on very large samples of high quality longitudinal data on real life care recipients in home care or nursing homes from Belgium, Canada, Finland, Hong Kong, Italy, New Zealand, the Netherlands and the USA.",
    questionnairePopupHeadingList5: "More info : www.icare4old.eu / Guidance text",
    description: "Description",
    wp5: "Wp5",
    wp6: "Wp6",
    wp6Prediction: "Wp6 Prediction",
    configurationWp5Form: "Configuration Wp5 Form",
    configurationWp6Form: "Configuration Wp6 Form",
    boxType: "Box Type",
    addNewSection: "Add New Section",
    translation: "Translation",
    upload: "Upload",
    noRecordFound: "No Record Found",
    attachments: "Attachments",
    questionnairePopupHeadingList6: "on this link and fill the form for further process of",
    preQuestionnaire: "pre-questionnaire",
    homeCare: "Home Care",
    nursingHome: "Nursing Home",
    afterGeneratePredictionPopup: "Did you get familiarized with patient profile?",
    thankYou: "Thank you!",
    predictionIn12Months: "Predictions in 12 months",
    functionWorsening: "Function worsening (ADL + IADL)",
    cognitionWorsening: "Cognition worsening (CPS)",
    healthInstability: "Health Instability (CHESS)",
    otherFalls: "Other (Falls)",
    qualityOfLifeWorsening: "Quality of Life worsening (HUI3)",
    riskOfDecline: "Risk of event",
    whatIfYouDecide: "What if you decide to:",
    probabilityOfHospitalization: "Probability of hospitalization to a medical facility in the next 12 months.",
    probabilityOfWorseningIADL: "Probability of having significant worsening in IADL in the next 12 months.",
    otherFallsInfoBox: "The individual treatment effect (ITE) is the difference between the predicted effect after starting of anticholinergic medication and the predicted effect if the patient does not receive medication. A predicted effect lower than 0 indicates lower probability of falls after starting of medication compared to no medication. A predicted effect higher than 0 indicates higher probability of falls with medication compared to no medication. timeframe: a lookback period of 90 days after 12 months",
    lastAllInfoBox: "The individual treatment effect (ITE) is the difference between the predicted effect under treatment and the predicted effect if the patient does not receive treatment. A predicted effect lower than 0 indicates less decline, no decline, or greater improvement with therapy compared to no therapy. A predicted effect higher than 0 indicates a greater decline with therapy compared to no therapy. Timeframe: up do 6 months",
    hospitalizations: "Hospitalizations",
    forgotYourPassword: "Forgot your password?",
    forgotPasswordInstructions: "Please enter your email and we will send you email with forward instructions",
    forgotPasswordHeading: "Please note: Email is valid for 24 hours",
    submit: "Submit",
    resetYourPassword: "Reset your password?",
    pleaseEnterNewPassword: "Please enter new password",
    resetPasswordHeading: "Please note: Email is valid for 24 hours",
    setupYourPasswordForNewAccount: "Setup Your password for new account",
    resetPasswordNewHeading: "Please enter your password and your account will register with us and you can able to login.",
    resetPasswordNewHeading1: "Please note: Email is valid for 24 hours",
    patientProfilePredictionVerification: "Patient Profile prediction verification",
    configurationSectionForm: "Configuration Section Form",
    position:"Position",
    after:"After",
    before:"Before",

};

export default {
    locale: "en-US",
    messages: {
        title: titles
    },
};
