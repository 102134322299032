const title = {
    patientProfileList: "Seznam profilů pacientů",
    hello: "Ahoj",
    patientName: "Jméno pacienta",
    patientId: "ID pacienta",
    creationDate: "Datum vytvoření",
    lastUpdate: "Poslední aktualizace",
    status: "Stav",
    action: "Akce",
    profileId: "ID profilu",
    sizeOfProfilePopulation: "Velikost populace profilu",
    numberOfInclusionRules: "Počet pravidel zařazení",
    numberOfRiskGroups: "Počet rizikových skupin",
    modelProfileList: "Seznam modelových profilů",
    view: "Zobrazit",
    export: "Export",
    import: "Import",
    completed: "Dokončeno",
    delete: "Smazat",
    generatePrediction: "Generovat predikci",
    draft: "Koncept",
    addNewPatient: "Přidat nového pacienta",
    patientProfile: "Profil pacienta",
    predictionGenerators: "Generátory predikcí",
    readMission: "Rehospitalizace",
    basedOnModels: "Na základě modelů",
    frailtyPredictions: "Predikce křehkosti",
    predictedIndividualTreatmentEffect: "Predikovaný individuální léčebný efekt",
    deleteProfile: "Smazat profil",
    editProfile: "Upravit profil",
    dashboard: "Nástěnka",
    predictionModel: "WP4 model readmise",
    update: "Aktualizovat",
    editPatientProfile: "Upravit profil pacienta",
    generalInformation: "Obecné informace",
    fullName: "Celé jméno",
    countryOfOrigin: "Země původu",
    chf: "CHF",
    copd: "KOPD",
    uti: "MOČOVÁ INF",
    emailAddress: "E-mailová adresa",
    password: "Heslo",
    forgotPassword: "Zapomněli jste heslo",
    login: "Přihlášení",
    signInToYourAccount: "Přihlaste se do svého účtu",
    welcomeBack: "Vítejte zpět",
    hospitalization_history: "HISTORIE HOSPITALIZACE",
    usti: "UTI",
    deteriorated_more_services: "ZHOŘEL VÍCE SLUŽEB",
    stasis_ulcer: "ULCUS STASIS",
    skin_tears_or_cut: "ŘEZY NEBO ŘEZY",
    lessons: "LEKCE",
    fallany: "PADÁNY",
    other_fracture: "JINÁ ZLOMENINA",
    cancer: "RAKOVINA",
    infuse: "INFUZE",
    dialysis: "DIALÝZA",
    er_visits_in_the_last_90_days_or_since_last_assessment: "NÁVŠTĚVY ER V POSLEDNÍCH 90 DNECH NEBO OD POSLEDNÍHO POSOUDKU",
    renal_failure: "RENÁLNÍ SELHÁNÍ",
    gender: "Pohlaví",
    irregular_pulse: "NEPRAVIDELNÝ PULZ",
    unintended_weight_loss_of_5_percent_or_more_in_last_30_days: "NEZAMÝŠLENÁ ZTRÁTA HMOTNOSTI 5% NEBO VÍCE ZA POSLEDNÍCH 30 DNŮ",
    ulcer_wound_care_surgical_wound_care: "ÚCHYLKA RAN LÉKAŘSKÁ RANÁ PÉČE",
    pressure_ulce: "TLAKOVÉ ULCE",
    hip_fracture: "ZLOMENINA KYČLE",
    client_feels_having_a_poor_health: "KLIENT MÁ ŠPATNÉ ZDRAVÍ",
    experiencing_flare_up_of_recurrent_condition_or_problem: "PŘETRVÁVÁNÍ ZNOVUJÍCÍHO SE PROBLÉMU NEBO PROBLÉMU",
    adl_decline: "Úbytek schopnosti základních denních aktivit",
    baseline_frailty_score: "Počáteční míra křehkosti",
    baseline_frailty_subset1_cognition_collapsed: "Počáteční podmnožina křehkosti 1 - kolaps kognice",
    baseline_frailty_subset1_clinical_collapsed: "Počáteční podmnožina křehkosti 1 - klinický kolaps",
    short_term_memory: "Krátkodobá paměť",
    worsening_of_decision_making: "Zhoršení rozhodování",
    alzheimers: "Alzheimerova choroba",
    dementia_other_than_heart_disease: "Demence kromě srdečních chorob",
    parkinsonism: "Parkinsonismus",
    eating: "Stravování",
    tranferring_oneself_from_surfaces: "Přenos sebe sama z povrchů",
    bladder_devices_indwelling_urinary_catheter: "Močové katétry v močovém měchýři",
    pneumonia: "Zápal plic",
    procedural_memory: "Procedurální paměť",
    fever: "Horečka",
    diagnosis: "Diagnóza",
    age: "Věk",
    unplanned_hospital_or_er_visit: "Naplanovaná návštěva nemocnice nebo ER",
    number_of_comorbidities: "Počet komorbidit",
    unsteady_gait: "Nestabilní chůze",
    diuretics: "Diuretika",
    pain_scale: "Škála bolesti",
    client_feels_he_she_has_poor_health: "Klient se cítí, že má špatné zdraví",
    chess: "Šachy",
    adl_status_has_become_worse: "Stav ADL se zhoršil",
    security_service: "Bezpečnostní služba",
    visiting_nurses: "Návštěva zdravotních sester",
    client_went_out_of_the_house_1_day_a_week_or_no_days: "Klient vyšel z domu (1 den v týdnu nebo žádný den)",
    impaired_vision: "Poškozené vidění",
    caregivers_belief_client_capable: "Pěstoun věří, že klient je schopen",
    drs: "DRS",
    psychotropic_medication: "Psychotropní léky",
    bmi: "BMI",
    adl_long: "ADL DLOUHÉ",
    cancel: "Zrušit",
    saveAndClose: "Uložit a zavřít",
    enterToSearch: "Stiskněte Enter pro hledání",
    viewModelList: "Seznam modelů",
    chancesHospitalizationInNext12Months: "Šance na hospitalizaci v následujících 12 měsících",
    inclusionRules: "Pravidla zařazení",
    variableIndex: "Index proměnné",
    variableName: "Název proměnné",
    variableValue: "Hodnota proměnné",
    valueSymetricOddScore: "Hodnota (Symetrické) Liché skóre",
    oddSymetricScoreSet: "Liché Symetrické Skóre",
    riskGroups: "Rizikové skupiny",
    threshold: "Práh",
    dependent_value: "Závislá hodnota",
    performance: "Výkon",
    sample_size: "Vzorná velikost",
    previous_hospitalization_events_in_last_year: "Předchozí hospitalizační události v posledním roce",
    welcome: "Vítejte!",
    back: "Zpět",
    frailtyPrediction: "Predikce křehkosti",
    viewModelProfile: "Zobrazit model profilu",
    users: "Uživatelé",
    usersList: "Seznam uživatelů",
    addNewUser: "Přidat nového uživatele",
    name: "Jméno",
    email: "Email",
    isVerified: "Je ověřen",
    yes: "Ano",
    no: "Ne",
    edit: "Upravit",
    resendActivationLink: "Znovu odeslat aktivační odkaz",
    add: "Přidat",
    user: "Uživatel",
    passwordConfirmation: "Potvrzení hesla",
    newPatientProfile: "Nový profil pacienta",
    userForm: "Formulář uživatele",
    modelProfile: "Modelový profil",
    newModelProfile: "Nový modelový profil",
    locomodation_in_home: "Ubytování doma",
    meal_preparation: "Příprava jídla",
    stairs: "Schody",
    short_term_memory_ok: "Krátkodobá paměť",
    alzheimers_disease: "Alzheimerova choroba",
    dementia_other_than_alzheimers_disease: "Demence jiná než Alzheimerova choroba",
    paraplegia: "Paraplegie",
    mental_health_delusions: "Mental Health bludy",
    mental_health_hallucinations: "Mental Health halucinace",
    bowel_continence: "Střevní kontinence",
    meal: "Jídlo",
    housework: "Domácí práce",
    shopping: "Nákupy",
    iadl_alzheimers: "IADL Alzheimer",
    other_dementia: "Další demence",
    bath: "Koupel",
    difficulty_meal_preparation: "Obtížnost přípravy jídel",
    revised_iadl_hierarchy: "revidovaná hierarchie IADL",
    short_memory_ok: "Krátkodobá paměť ok",
    iadl_decline: "Pokles IADL",
    iadlModel: "WP4 model IADL",
    iadlProfileList: "Seznam profilů IADL",
    iadlProfile: "Profil IADL",
    viewIadlModelList: "Zobrazit seznam modelů IADL",
    viewIadlModel: "Zobrazit model IADL",
    individualTreatment: "Individuální léčba",
    seeAll: "Zobrazit vše",
    iadlDecline: "HC: Pokles IADL",
    hospitalization: "HC: Hospitalizace",
    qualityOfLife: "NH: Kvalita života",
    frailty: "NH: Křehkost",
    viewQualityOfLifeList: "Zobrazit seznam kvality života",
    viewFrailtyList: "Zobrazit seznam křehkosti",
    viewFrailty: "Zobrazit křehkost",
    qualityOfLifeN: "Kvalita života",
    frailtyN: "Křehkost",
    hospitalizationTooltip: "Individuální léčebný efekt (ITE), což je absolutní míra rizika, která ukazuje změnu (oproti základní hodnotě) v pravděpodobnosti hospitalizace (v následujících 12 měsících) pro danou osobu v důsledku léčby",
    adlTooltip: "Změna (oproti základní hodnotě) v předpovězené hodnotě výsledku pro danou osobu v důsledku léčby. Například ITE +1,2 je změna předpovězeného skóre způsobená ITE (na stupnici od 0 do 6) pro danou osobu. Toto číslo lze vyjádřit v procentech: změna skóre CPS v důsledku ITE je 20% zvýšení (1,2/6*100) pro danou osobu",
    cpsTooltip: "Změna (oproti základní hodnotě) v předpovězené hodnotě výsledku pro danou osobu v důsledku léčby. Například ITE +1,2 je změna předpovězeného skóre způsobená ITE (na stupnici od 0 do 6) pro danou osobu. Toto číslo lze vyjádřit v procentech: změna skóre CPS v důsledku ITE je 20% zvýšení (1,2/6*100) pro danou osobu",
    chessTooltip: "Změna (oproti základní hodnotě) v předpovězené hodnotě výsledku pro danou osobu v důsledku léčby. Například ITE +1,2 je změna předpovězeného skóre způsobená ITE (na stupnici od 0 do 6) pro danou osobu. Toto číslo lze vyjádřit v procentech: změna skóre CPS v důsledku ITE je 20% zvýšení (1,2/6*100) pro danou osobu",
    fallsTooltip: "Individuální léčebný efekt (ITE), což je absolutní míra rizika, která ukazuje změnu (oproti základní hodnotě) v pravděpodobnosti pádu (v následujících 3-6 měsících) pro danou osobu v důsledku léčby",
    role: "Role",
    isLogin: "Je přihlášen?",
    prePilotDashboard: "Dashboard",
    isVisitFormLink: "Je to odkaz na formulář návštěvy?",
    category: "Kategorie",
    type: "Typ",
    baseOnModel: "Založeno na modelu",
    item: "Položka",
    addSuccessfully: "Úspěšně přidáno",
    updateSuccessfully: "Úspěšně aktualizováno",
    errorMessage: "Ops, něco se pokazilo, zkuste to znovu",
    questionSubmittedSuccess: "Otázka úspěšně odeslána",
    deletedSuccess: "Úspěšně smazáno",
    resetPasswordSuccessMsg: "Odkaz na resetování hesla byl úspěšně odeslán na váš e-mail.",
    successLogin: "Úspěšně jste se přihlásili",
    otpSuccessMsg: "OTP úspěšně odesláno na váš e-mail",
    alreadyRegistered: "Již registrováno!",
    otpVerifiedMsg: "OTP úspěšně ověřeno!",
    otpSentSuccessMsg: "OTP úspěšně odesláno!",
    registerSuccessMsg: "Úspěšná registrace!",
    tokenInvalidMsg: "Token je neplatný nebo vypršel, zkuste to znovu",
    passwordResetSuccessMsg: "Heslo úspěšně obnoveno!",
    yourRequestSubmitSuccessMsg: "Váš požadavek byl úspěšně odeslán",
    patientImportSuccessMsg: "Import pacienta úspěšný",
    deletedSuccessMsg: "Úspěšně smazáno",
    accountAlreadyExistsMsg: "Váš účet je úspěšně registrován u nás, prosím přihlaste se nyní!",
    fileUploadSuccessMsg: "Soubor úspěšně nahrán",
    fileDeleteMsg: "Soubor úspěšně smazán",
    click: "Klikněte",
    here: "Zde",
    postQuestionnaireSentence: "na tento odkaz a vyplňte formulář pro další proces",
    postQuestionnaire: "post-dotazník",
    hospitalizationTooltip2: "Pravděpodobnost hospitalizace ve zdravotnickém zařízení v příštích 12 měsících.",
    adlTooltip2: "Pravděpodobnost výrazného zhoršení IADL v následujících 12 měsících.",
    patient: 'Pacient',
    predictionTopic: "Předpovědi za 12 měsíců",
    riskOfDeclineOfEvent: "Riziko odmítnutí / události ",
    whatIfYouDecideTo: "Co když se rozhodnete:",
    stopAntipsychotics: "Ukončení užívání antipsychotik:",
    startAnticholinergics: "Zahájení užívání anticholinergik:",
    startPhysiotherapy: "Zahájení fyzioterapie",
    predictedChange: "Předpokládaná změna",
    adl: "ADL",
    iadl: "IADL",
    cps: "CPS",
    falls: "PÁDY",
    configuration: "Konfigurace",
    code: "Kód",
    label: "Štítek",
    index: "Index",
    wp4: "WP4",
    configurations: "Konfigurace",
    configurationWp4Form: "Konfigurační formulář WP4",
    configurationWp4SectionForm: "Konfigurační formulář sekce WP4",
    wp4Section: "Sekce WP4",
    title: "Titul",
    section: "Sekce",
    isEmailVerified: "Je e-mail ověřen?",
    areYourSureWantToLogout: "Jste si jisti, že chcete odhlásit?",
    postQuestion: "pro rozhodovací dotazník.",
    toProvideFeedback: "poskytnout zpětnou vazbu.",
    logout: "Odhlásit se",
    clone: "Klonovat",
    cloneSuccessMsg: "Úspěšně klonována data pacienta",
    codeSystem: "Kódový systém",
    importExternalPatient: "Import externího pacienta",
    addPatient: "Přidat pacienta",
    patientList: "Seznam pacientů",
    patientForm: "Formulář pacienta",
    save: "Uložit",
    link1: "Odkaz1",
    link2: "Odkaz2",
    patientFeedbackLink: "Odkaz na zpětnou vazbu pacienta",
    forDecisionQuestionnaire: "pro rozhodovací dotazník",
    link: "Odkaz",
    showRaiData: "Zobrazit data RAI",
    startPhysiotherapyContent: "časový rámec: až 6 měsíců. definice PT: nejméně 4 sezení týdně (pro ADLLF) nebo 5 dnů (pro CPS), každé sezení trvá minimálně 15 minut",
    startPhysiotherapyContent1: "Individuální léčebný účinek (ITE) je rozdíl mezi předpokládaným účinkem při léčbě a předpokládaným účinkem, pokud pacient léčbu nedostane. Předpokládaný účinek nižší než 0 znamená menší zhoršení, žádné zhoršení nebo větší zlepšení při terapii ve srovnání s terapií. Předpokládaný účinek vyšší než 0 znamená větší zhoršení při terapii ve srovnání s terapií.",
    startPhysiotherapyContent2: "Individuální léčebný účinek (ITE) je rozdíl mezi předpokládaným účinkem při léčbě a předpokládaným účinkem, pokud pacient léčbu nedostane. Předpokládaný účinek vyšší než 0 znamená menší zhoršení, žádné zhoršení nebo větší zlepšení při terapii ve srovnání s terapií. Předpokládaný účinek nižší než 0 znamená větší zhoršení při terapii ve srovnání s terapií.",
    startPhysiotherapyContentAdl: "Individuální léčebný účinek (ITE) je rozdíl mezi predikovaným účinkem při léčbě a predikovaným účinkem v případě, že pacient léčbu nedostane. Predikovaný účinek nižší než 0 znamená menší pokles, žádný pokles nebo dotazníkvětší zlepšení při léčbě ve srovnání s žádnou léčbou. Predikovaný účinek vyšší než 0 znamená větší pokles při léčbě ve srovnání s žádnou léčbou; Časové období: až 6 měsíců; Výsledek: ADL , od 0 do 28 bodů",
    stopAntipsychoticsContentAdl: "Individuální léčebný účinek (ITE) je rozdíl mezi předpokládaným účinkem po vysazení antipsychotik a předpokládaným účinkem při pokračování léčby. Předpokládaný účinek menší než 0 znamená menší zhoršení, žádné zhoršení nebo větší zlepšení ADL při vysazení antipsychotik ve srovnání s pokračováním léčby. Předpokládaný účinek větší než 0 znamená větší zhoršení ADL při vysazení antipsychotik ve srovnání s pokračováním léčby. Časový rámec: až 12 měsíců",
    stopAntipsychoticsContentCps: "Individuální léčebný účinek (ITE) je rozdíl mezi predikovaným účinkem po vysazení antipsychotické medikace a predikovaným účinkem při pokračování medikace. Predikovaný účinek menší než 0 znamená menší pokles, žádný pokles nebo větší zlepšení CPS při vysazení antipsychotické medikace ve srovnání s pokračující medikací. Predikovaný účinek větší než 0 znamená větší pokles CPS při vysazení antipsychotické medikace ve srovnání s pokračující medikací. Časový rámec: až 12 měsíců",
    stopAntipsychoticsContentHospitalization: "Individuální léčebný účinek (ITE) je rozdíl mezi predikovaným účinkem po vysazení antipsychotické medikace a predikovaným účinkem při pokračování medikace. Predikovaný účinek menší než 0 znamená nižší pravděpodobnost hospitalizace při vysazení antipsychotické medikace ve srovnání s pokračováním medikace. Predikovaný účinek větší než 0 znamená vyšší pravděpodobnost hospitalizace při vysazení antipsychotické medikace ve srovnání s pokračující medikací. Časový rámec: až 12 měsíců",
    stopAntipsychoticsContentChess: "Individuální léčebný účinek (ITE) je rozdíl mezi predikovaným účinkem po vysazení antipsychotické medikace a predikovaným účinkem při pokračování medikace. Předpovídaný účinek menší než 0 znamená menší pokles, žádný pokles nebo větší zlepšení CHESS při vysazení antipsychotické medikace ve srovnání s pokračující medikací. Předpovídaný účinek větší než 0 znamená větší pokles CHESS při vysazení antipsychotické medikace ve srovnání s pokračující medikací. Časový rámec: až 12 měsíců",
    stopAntipsychoticsContentFalls: "Individuální léčebný účinek (ITE) je rozdíl mezi predikovaným účinkem po zahájení anticholinergní medikace a predikovaným účinkem v případě, že pacient nedostává léky. Predikovaný účinek nižší než 0 znamená nižší pravděpodobnost pádů po zahájení medikace ve srovnání s žádnou medikací. Predikovaný účinek vyšší než 0 znamená vyšší pravděpodobnost pádů při medikaci ve srovnání s žádnou medikací. časový rámec: zpětná perioda 90 dnů po 12 měsících",
    startAnticholinergicsAdl: "Individuální léčebný účinek (ITE) je rozdíl mezi předpokládaným účinkem po zahájení léčby anticholinergiky a předpokládaným účinkem, pokud pacient léčbu nedostane. Předpokládaný účinek nižší než 0 znamená menší zhoršení, žádné zhoršení nebo větší zlepšení ADL po zahájení léčby ve srovnání s léčbou. Předpokládaný účinek vyšší než 0 znamená větší zhoršení ADL při léčbě ve srovnání s léčbou. časový rámec: až 12 měsíců",
    startAnticholinergicsCps: "Individuální léčebný účinek (ITE) je rozdíl mezi předpokládaným účinkem po zahájení léčby anticholinergiky a předpokládaným účinkem, pokud pacient léčbu nedostane. Předpokládaný účinek nižší než 0 znamená menší zhoršení, žádné zhoršení nebo větší zlepšení CPS po zahájení léčby ve srovnání s léčbou. Předpokládaný účinek vyšší než 0 znamená větší zhoršení CPS při léčbě ve srovnání s léčbou. časový rámec: až 12 měsíců",
    startAnticholinergicsHospitalization: "Individuální léčebný účinek (ITE) je rozdíl mezi předpokládaným účinkem po zahájení léčby anticholinergiky a předpokládaným účinkem, pokud pacient léčbu nedostane. Předpokládaný účinek nižší než 0 znamená menší pravděpodobnost hospitalizace po zahájení léčby ve srovnání s léčbou. Předpokládaný účinek vyšší než 0 znamená vyšší pravděpodobnost hospitalizace při léčbě ve srovnání s léčbou. časový rámec: až 12 měsíců",
    startAnticholinergicsFalls: "Individuální léčebný účinek (ITE) je rozdíl mezi předpokládaným účinkem po zahájení léčby anticholinergiky a předpokládaným účinkem, pokud pacient léčbu nedostane. Předpokládaný účinek nižší než 0 znamená menší pravděpodobnost pádů po zahájení léčby ve srovnání s léčbou. Předpokládaný účinek vyšší než 0 znamená vyšší pravděpodobnost pádů při léčbě ve srovnání s léčbou. časový rámec: 90 dní po 12 měsících",
    questionnairePopupHeading: "Ogólne zastrzeżenie dotyczące wszystkich prognoz:",
    questionnairePopupHeadingList1: "Należy zachować ostrożność i kierować się swoją wiedzą kliniczną przy podejmowaniu ostatecznych decyzji klinicznych, ponieważ trafność przedstawionych prognoz może różnić się zależnie od stanu konkretnego pacjenta.",
    questionnairePopupHeadingList2: "Prognozy te zostały opracowane i zatwierdzone przez konsorcjum I-CARE4OLD.",
    questionnairePopupHeadingList3: "Przewidywania zostały oparte na zaawansowanych analizach, w tym na uczeniu maszynowym. Zastosowaliśmy najlepsze metody naukowe z wykorzystaniem wysokiej jakości danych zebranych w ramach rutynowej opieki z wielu krajach.",
    questionnairePopupHeadingList4: "Oceny trafności modeli prognostycznych dokonano na bardzo dużych próbach wysokiej jakości obserwacji długookresowych (podłużnych) dotyczących rzeczywistych osób korzystających z opieki domowej (środowiskowej lub długoterminowej) lub opieki świadczonej w placówkach opiekuńczych (które w Polsce odpowiadają domom opieki i zakładom opiekuńczo-leczniczym) w Belgii, Kanadzie, Finlandii, Hongkongu, we Włoszech, Nowej Zelandii, Holandii i Stanach Zjednoczonych Ameryki Północnej.",
    questionnairePopupHeadingList5: "Więcej informacji: www.icare4old.eu",
    close: "Zavřít",
    description: "Popis",
    wp5: "Wp5",
    wp6: "Wp6",
    wp6Prediction: "Wp6 Předpověď",
    configurationWp5Form: "Konfigurace formuláře Wp5",
    configurationWp6Form: "Konfigurace formuláře Wp6",
    boxType: "Typ krabice",
    addNewSection: "Přidat novou sekci",
    translation: "Překlad",
    upload: "Nahrát",
    noRecordFound: "Záznam nenalezen",
    attachments: "Přílohy",
    questionnairePopupHeadingList6: "i wypełnij kwestionariusz wstępny.",
    preQuestionnaire: "před-dotazník",
    homeCare: "Domácí péče",
    nursingHome: "Pečovatelský dům",
    afterGeneratePredictionPopup: "Seznámili jste se s profilem pacienta?",
    thankYou: "Děkuji!",
    predictionIn12Months: "Předpověď za 12 měsíců",
    functionWorsening: "Zhoršení funkčního stavu (ADL+IADL)",
    cognitionWorsening: "Zhoršení kognice (CPS)",
    healthInstability: "Nestabilní zdravotní stav (CHESS)",
    otherFalls: "Ostatní (pády)",
    qualityOfLifeWorsening: "Zhoršení kvality života (HUI3)",
    riskOfDecline: "Riziko události",
    whatIfYouDecide: "Co když se rozhodnete:",
    probabilityOfHospitalization: "Pravděpodobnost hospitalizace do zdravotnického zařízení v následujících 12 měsících.",
    probabilityOfWorseningIADL: "Pravděpodobnost významného zhoršení v IADL v následujících 12 měsících.",
    otherFallsInfoBox: "Individuální léčebný účinek (ITE) je rozdíl mezi předpovězeným účinkem po zahájení užívání anticholinergních léků a předpovězeným účinkem, pokud pacient nedostává léky. Předpovězený účinek nižší než 0 znamená nižší pravděpodobnost pádů po zahájení léčby ve srovnání s neaplikováním léků. Vyšší účinek indikuje vyšší pravděpodobnost pádů s léčbou ve srovnání s žádnou léčbou. Časové období: období zpětného pohledu 90 dní po 12 měsících.",
    lastAllInfoBox: "Individuální léčebný účinek (ITE) je rozdíl mezi predikovaným účinkem při léčbě a predikovaným účinkem v případě, že pacient léčbu nedostane. Předpovídaný účinek nižší než 0 znamená menší pokles, žádný pokles nebo větší zlepšení při léčbě ve srovnání s žádnou léčbou. Předpovídaný účinek vyšší než 0 znamená větší pokles při léčbě ve srovnání s žádnou léčbou. Časový rámec: až do 6 měsíců",
    hospitalizations: "Hospitalizace",
    forgotYourPassword: "Zapomněli jste heslo?",
    forgotPasswordInstructions: "Zadejte prosím svůj e-mail a my vám pošleme e-mail s dalšími pokyny",
    forgotPasswordHeading: "Poznámka: E-mail je platný 24 hodin",
    submit: "Odeslat",
    resetYourPassword: "Obnovte své heslo?",
    pleaseEnterNewPassword: "Zadejte prosím nové heslo",
    resetPasswordHeading: "Poznámka: E-mail je platný 24 hodin",
    setupYourPasswordForNewAccount: "Nastavte si heslo pro nový účet",
    resetPasswordNewHeading: "Zadejte své heslo a váš účet bude u nás zaregistrován a budete se moci přihlásit.",
    resetPasswordNewHeading1: "Poznámka: E-mail je platný 24 hodin",
    patientProfilePredictionVerification: "Ověření predikce profilu pacienta",
    position:"Pozice",
    after:"Po",
    before:"Před"
};
export default {
    locale: "cs-CZ",
    messages: {
        title: title
    },
};
